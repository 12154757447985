import React from "react";

import { Answer, ICaseData } from "../../types/case";

export const TEST_ID_PROCEDURE_NOTES_SECTION = "ProcedureNotesSection";
export const TEST_ID_PROCEDURE_NOTES_HEADING = "ProcedureNotesHeading";

interface ProcedureNotesProps {
  caseData: ICaseData;
}

const ProcedureNotes = ({ caseData }: ProcedureNotesProps): JSX.Element => {
  const {
    didPatientSwallow,
    didSpongeExpand,
    wasTensionOnThread,
    isDebrisOnSponge,
    isBloodOnSponge,
    takesAnticoagulants,
    numberOfFailedAttempts,
  } = caseData;

  return (
    <section className="mb-4" data-testid={TEST_ID_PROCEDURE_NOTES_SECTION}>
      <h4 className="title is-6 mb-4" data-testid={TEST_ID_PROCEDURE_NOTES_HEADING}>
        Procedure notes
      </h4>
      <dl>
        <div className="cy-data-list__item">
          <dt>
            Do you think the patient swallowed the capsule so it reached their stomach?
          </dt>
          <dd>{didPatientSwallow}</dd>
        </div>
        <div className="cy-data-list__item">
          <dt>Did the sponge fully expand?</dt>
          <dd>{didSpongeExpand}</dd>
        </div>
        <div className="cy-data-list__item">
          <dt>Was there tension on the thread throughout withdrawal?</dt>
          <dd>{wasTensionOnThread}</dd>
        </div>
        <div className="cy-data-list__item">
          <dt>Is there debris on the sponge?</dt>
          <dd>{isDebrisOnSponge}</dd>
        </div>
        <div className="cy-data-list__item">
          <dt>Is there blood on the sponge?</dt>
          <dd>{isBloodOnSponge}</dd>
        </div>
        {isBloodOnSponge === Answer.YES && (
          <div className="cy-data-list__item">
            <dt>Does the patient take anticoagulants?</dt>
            <dd>{takesAnticoagulants}</dd>
          </div>
        )}
        <div className="cy-data-list__item">
          <dt>Number of failed attempts</dt>
          <dd>{numberOfFailedAttempts}</dd>
        </div>
      </dl>
    </section>
  );
};

export default ProcedureNotes;
