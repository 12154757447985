import { Authenticator } from "@aws-amplify/ui-react";
import * as Sentry from "@sentry/react";
import { Amplify } from "aws-amplify";
import { I18n } from "aws-amplify/utils";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import ReportBuilder from "./routes/ReportBuilder";
import Root from "./routes/Root";
import UserProfile from "./routes/UserProfile";
import { store } from "./store";

function NotFound() {
  return <h1>Not Found</h1>;
}

Sentry.init({
  dsn: "https://78fcf35948c44579bd2c7fd708ff95a4@o429717.ingest.sentry.io/4504616905211904",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/api.*pathkit\.cytedhealth\.com/],
  environment: import.meta.env.VITE_APP_ENVIRONMENT ?? "local-development",

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_APP_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_APP_USER_POOL_APP_CLIENT_ID,
    },
  },
});

// Override Amplify Authenticator MFA user interface
I18n.setLanguage("en");
I18n.putVocabularies({
  en: { "Confirm TOTP Code": "Two-factor authentication" },
});
const formFields = {
  confirmSignIn: {
    confirmation_code: {
      type: "text", // Remove number arrow buttons
      label: "Authenticator app code",
      placeholder: "6-digit code",
      autoFocus: true,
    },
  },
};

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <ReportBuilder />,
      },
      {
        path: "profile",
        element: <UserProfile />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Authenticator hideSignUp className="mt-6" formFields={formFields}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </Authenticator>
  </React.StrictMode>
);
